import { create } from 'zustand';
import { LeadType } from '@php-beam/packages/frontend/src/types/lead';
import { PusherEventData } from '@php-beam/packages/frontend/src/types/shared';
import { UpdateAppointmentType } from '@php-beam/packages/frontend/src/types/appointment';

type ModalMode = 'prompt' | 'confirm';

type SetModalParams<T extends ModalMode> = {
  modalMode: T;
  promptMsg: string;
  promptCallback: (message: string | boolean) => void;
  defaultValue?: string;
  cancelBtnLabel?: string;
  confirmBtnLabel?: string;
  modalTitle?: string;
};

export type GlobalStore = {
  isLoading: boolean;
  _globalUi: {
    modal: {
      modalMode?: ModalMode;
      promptMsg: string;
      promptCallback?: (message: string | boolean) => void;
      showModal: boolean;
      cancelBtnLabel?: string;
      confirmBtnLabel?: string;
      defaultValue?: string;
      modalTitle?: string;
    };

    leadCardDrawer: {
      showDrawer: boolean;
      lead?: LeadType;
    };

    appointmentBuilder: {
      showAppointmentBuilder: boolean;
      leadAppointment?: LeadType;
    };

    appointmentUpdater: {
      showAppointmentUpdater: boolean;
      appointment?: UpdateAppointmentType;
    };

    pusherEvent: {
      showPusherEvent: boolean;
      event?: PusherEventData;
    };
  };

  setModal: <T extends ModalMode | undefined>(
    params: T extends ModalMode ? SetModalParams<T> : false,
  ) => void;

  openLeadDrawer: (props: { lead?: LeadType; showDrawer?: boolean }) => void;

  openNewAppointment: (props: {
    leadAppointment?: LeadType;
    showAppointmentBuilder?: boolean;
  }) => void;

  setIsLoading: (value: boolean) => void;

  openAppointmentUpdater: (props: {
    appointment?: UpdateAppointmentType;
    showAppointmentUpdater?: boolean;
  }) => void;

  openPusherEvent: (props: {
    event?: PusherEventData;
    showPusherEvent?: boolean;
  }) => void;
};

const useGlobalStore = create<GlobalStore>((set, get) => ({
  _globalUi: {
    modal: {
      showModal: false,
      promptMsg: '',
      cancelBtnLabel: undefined,
      confirmBtnLabel: undefined,
    },
    leadCardDrawer: {
      showDrawer: false,
    },
    appointmentBuilder: {
      showAppointmentBuilder: false,
    },
    appointmentUpdater: {
      showAppointmentUpdater: false,
    },
    pusherEvent: {
      showPusherEvent: false,
    },
  },
  isLoading: false,

  setModal: (props) =>
    set({
      _globalUi: {
        ...get()._globalUi,
        modal:
          props === false
            ? {
                modalMode: undefined,
                promptMsg: '',
                promptCallback: undefined,
                showModal: false,
                defaultValue: undefined,
              }
            : { ...props, showModal: true },
      },
    }),
  setIsLoading: (value: boolean) =>
    set((state) => ({
      ...state,
      isLoading: value,
    })),

  openLeadDrawer: ({ lead, showDrawer }) =>
    set({
      _globalUi: {
        ...get()._globalUi,
        leadCardDrawer: {
          lead,
          showDrawer: showDrawer ?? true,
        },
      },
    }),

  openNewAppointment: ({ leadAppointment, showAppointmentBuilder }) =>
    set({
      _globalUi: {
        ...get()._globalUi,
        appointmentBuilder: {
          leadAppointment,
          showAppointmentBuilder: showAppointmentBuilder ?? true,
        },
      },
    }),

  openAppointmentUpdater: ({ appointment, showAppointmentUpdater }) =>
    set({
      _globalUi: {
        ...get()._globalUi,
        appointmentUpdater: {
          appointment,
          showAppointmentUpdater: showAppointmentUpdater ?? true,
        },
      },
    }),

  openPusherEvent: ({ event, showPusherEvent }) =>
    set({
      _globalUi: {
        ...get()._globalUi,
        pusherEvent: {
          event,
          showPusherEvent: showPusherEvent ?? true,
        },
      },
    }),
}));

export default useGlobalStore;
