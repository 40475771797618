import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
  VITE_PUSHER_APP_CLUSTER,
  VITE_PUSHER_APP_KEY,
} from '@php-beam/packages/frontend/src/config';
import axios from 'axios';
import Dinero from 'dinero.js';
import { DateRange } from '@php-beam/packages/frontend/src/types/requests';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { LeadType } from '@php-beam/packages/frontend/src/types/lead';
import localeDate from 'dayjs/plugin/localeData';
import es from 'dayjs/locale/es';
import en from 'dayjs/locale/en';
import LighticoRegistration = JanusPhoenix.Backend.Models.Administration.LighticoRegistration;

export const dayjsLocales = { es, en };

dayjs.extend(relativeTime);

export const uploadToPath = async <T>(
  file: File,
  path: string,
  transformer?: (body: FormData) => FormData,
) => {
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await axios.post(
    path,
    transformer ? transformer(formData) : formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return data as T;
};

export const formatPhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2 - $3');

export const cleanPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.replace(/\D/g, '');
};

export const formatCurrency = (value: number): string =>
  value?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  }) ?? formatCurrency(0);

/* Alias for formatCurrency, to match other formatting functions */
export const fmtCurrency = (value: number) => formatCurrency(value);

export const getQueryParam = (key: string, onDefault?: string): string => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(key) ?? onDefault ?? '';
};

export const fmtDate = (date: Date) => {
  return dayjs(date).format('MM/DD/YYYY h:mm A');
};

export const parseDateRangeValue = (value: DateRange | string): string => {
  if (typeof value === 'string') {
    return value;
  }

  if (value.from && value.to) {
    return `${startOrEndFullDayDate(
      'start',
      value.from,
    )},${startOrEndFullDayDate('end', value.to)}`;
  }

  return value as unknown as string;
};

export const startOrEndFullDayDate = (
  startOrEnd: 'start' | 'end',
  date?: Date,
) => {
  if (!date) {
    if (startOrEnd === 'start') {
      return dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    return dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');
  }

  if (startOrEnd === 'start') {
    return dayjs(date).startOf('day').format('YYYY-MM-DD HH:mm:ss');
  }
  return dayjs(date).endOf('day').format('YYYY-MM-DD HH:mm:ss');
};

export const fmtDateOnlyDate = (date: Date | string) => {
  return dayjs(date).format('MM/DD/YYYY');
};

export const isInViewport = function (elem: HTMLElement) {
  const bounding = elem.getBoundingClientRect();

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const fromNow = (date: string | Date) => dayjs(date).fromNow();

export const fromNowDate = (date: string | Date) => {
  if (dayjs(date).diff(new Date(), 'day') < -5) {
    return dayjs(date).format('MM/DD/YYYY h:mm');
  }
  return dayjs(date).fromNow();
};

export const toNow = (date: string | Date, withoutSuffix = true) =>
  dayjs(date).toNow(withoutSuffix);

export const toUSD = (value: number) =>
  Dinero({ amount: value, currency: 'USD' }).toFormat();

export const ucfirst = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const rmSlug = (str: string) => {
  return str.replaceAll('_', ' ');
};

export const removeEmptyFromObject = <T extends Record<any, any>>(
  obj: T,
): NonNullable<T> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== null && value !== undefined) {
      acc[key as keyof T] = value;
    }

    return acc;
  }, {} as T);
};

export const removeKeysFromObject = <T extends Record<any, any>>(
  obj: T,
  keys: string[],
): NonNullable<T> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!keys.includes(key)) {
      acc[key as keyof T] = value;
    }

    return acc;
  }, {} as T);
};

export const parseNullObjectValuesToEmptyString = (
  obj: Record<string, string> | any,
) => {
  const result = { ...obj };

  Object.keys(result).forEach((key) => {
    if (result[key] === null) {
      result[key] = '';
    }
  });

  return result;
};

export const parseDialpadMessage = <T>(message: string): T => {
  return JSON.parse(
    message.replaceAll("'", '"').replaceAll('"{', '{').replaceAll('}"', '}'),
  );
};

export const objectToFormData = (obj: Record<string, any>) => {
  const formData = new FormData();

  Object.keys(obj).forEach((key) => {
    const v = obj[key];
    formData.append(key, typeof v === 'boolean' ? (v ? '1' : '0') : v);
  });

  return formData;
};

export const setupLaravelEcho = () => {
  if (window.Echo) return;
  window.Echo = new Echo({
    broadcaster: 'pusher',
    key: VITE_PUSHER_APP_KEY,
    cluster: VITE_PUSHER_APP_CLUSTER,
    forceTLS: true,
    pusher: {
      Pusher,
    },
  });
};

export const randomStr = (length = 6) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const questionnaireAnswerFields = (questionnaire: object) => {
  return Object.fromEntries(
    Object.keys(questionnaire).map((key) => [`${key}answer`, '']),
  );
};

export const nameify = (str: string) => {
  return str
    .replace(/[^a-z0-9]/gi, '_')
    .replace(/_+/g, '_')
    .toLowerCase();
};

export const isLargerThan768 = '(min-width: 768px)';
export const SM = '(min-width: 30em)';
export const MD = '(min-width: 48em)';
export const LG = '(min-width: 62em)';
export const XL = '(min-width: 80em)';
export const XL2 = '(min-width: 96em)';

export const hasItems = (obj: Record<any, any> = {}) =>
  Object.keys(obj ?? {}).length > 0;

export const checkWorkflowDisabled = (
  registration: LighticoRegistration,
  lead: LeadType,
) => {
  if (registration.tag === 'ee_app') {
    return !(
      lead.active_quote &&
      ['peopaygo', 'compeo', 'cornerstone', 'coguard', 'southeast'].includes(
        lead.carrier,
      )
    );
  }
  if (registration.name === 'Authorized Representative Form') {
    return !['peopaygo', 'compeo', 'cornerstone'].includes(lead.carrier);
  }
  if (registration.tag === 'separation_notice') {
    return !['peopaygo', 'compeo', 'cornerstone'].includes(lead.carrier);
  }
  if (registration.name === 'Class Code Addition') {
    return true;
  }
  if (registration.tag === 'csa' && lead.carrier === 'coguard') {
    return true;
  }
};

export const setWorkflowTooltip = (
  registration: LighticoRegistration,
  lead: LeadType,
) => {
  if (registration.tag === 'ee_app') {
    if (lead.carrier === null) {
      return 'Select a Carrier';
    }
    if (
      !['peopaygo', 'compeo', 'cornerstone', 'coguard', 'southeast'].includes(
        lead.carrier,
      )
    ) {
      return 'Carrier not supported';
    }
    if (!lead.active_quote) {
      return 'Set an active quote';
    }
  }
  if (registration.tag === 'separation_notice') {
    if (lead.carrier === null) {
      return 'Select a Carrier';
    }
    if (!['peopaygo', 'compeo', 'cornerstone'].includes(lead.carrier)) {
      return 'Carrier not supported';
    }
  }
  if (registration.name === 'Class Code Addition') {
    return 'WORKFLOW DISABLED';
  }
  if (registration.tag === 'csa' && lead.carrier === 'coguard') {
    return 'Flat Type not supported';
  }
};

export const checkEEAppDisabled = (lead: LeadType) => {
  return !(lead.active_quote && lead.carrier !== null);
};

export const setEEAppTooltip = (lead: LeadType) => {
  return !lead.active_quote
    ? 'Set an active quote'
    : lead.carrier === null
    ? 'Select a Carrier'
    : null;
};

export const hasActiveQuote = (lead: LeadType) => {
  return lead.active_quote !== null;
};

export const checkPaymentFormDisabled = (lead: LeadType) => {
  if (!hasActiveQuote(lead)) {
    return true;
  }

  return !['compeo', 'cornerstone', 'peopaygo', 'coguard'].includes(
    lead.carrier ?? '',
  );
};

export const setPaymentFormTooltip = (lead: LeadType) => {
  if (!hasActiveQuote(lead)) {
    return 'Set an active quote';
  }

  return checkPaymentFormDisabled(lead)
    ? 'Carrier not supported'
    : lead.carrier === null
    ? 'Select a Carrier'
    : null;
};

export const getLastDayOfMonth = (date: Date) =>
  dayjs(date).endOf('month').toDate().getDate();

export const getLastDayOfMonthFromDateNumber = (month: number) => {
  const date = new Date();
  date.setMonth(month);

  return dayjs(date).endOf('month').toDate().getDate();
};

export const getDateLocaleValues = (locale: 'es' | 'en' = 'en') => {
  dayjs.locale(dayjsLocales[locale]);
  dayjs.extend(localeDate);
  return {
    months: dayjs.months(),
    months_short: dayjs.monthsShort(),
    weekdays: dayjs.weekdays(),
    weekdays_short: dayjs.weekdaysShort(),
    weekdays_min: dayjs.weekdaysMin(),
  };
};

export const getKeyWithoutValue = (
  obj: Record<string, string>,
  _value: string,
) => Object.entries(obj).find(([key, value]) => value === _value)?.[0];

export const toBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export const isBase64 = (value: string) => {
  try {
    window.atob(value);
    return false;
  } catch (e) {
    return true;
  }
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  try {
    let arr = dataurl.split(','),
      // @ts-ignore
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  } catch (e) {
    // console.log('data url to file failed', e);
    return dataurl;
  }
};

export const parseBase64ValuesToFile = (form: Record<any, any>) => {
  for (const key in form) {
    const value = form[key];

    form[key] =
      typeof value === 'string' && isBase64(value)
        ? dataURLtoFile(value, 'file_name.jpg')
        : value;
  }

  return form;
};

export const addDays = (date: Date, days: number) => {
  return new Date(date.setDate(date.getDate() + days));
};

export const generateSimpleLeadPassword = (lead: LeadType) => {
  const fullName = lead.primary_contact?.name;
  const computedName = fullName
    ? (fullName.charAt(0) + fullName.slice(1).toLowerCase()).replaceAll(' ', '')
    : '';
  const last4OfPhoneNumber = lead.primary_contact?.first_phone?.value.slice(-4);

  return `${computedName}${last4OfPhoneNumber}`;
};

export const range = (start: number, end: number, step?: number) => {
  const length = Math.floor((end - start) / (step ?? 1)) + 1;
  return Array.from({ length }, (_, i) => start + (step ?? 1) * i);
};
